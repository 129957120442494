import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Container from '../Container/Container'

// Local components
import SwiperNavCta from './SwiperNavCta'

const SwiperNav = (props) => {
  const { swiper, wrapperClassName, containerClassName, ctaClassName } = props

  /**
   * Click hanlder for each SwiperNavCta component
   * @param  {Object} event
   * @param  {String} directionMethod - Which method to call on the swiper
   *                                    instance when the CTA is clicked
   * @return {Void}
   */
  const handleOnClick = (event, directionMethod) => {
    event.preventDefault()

    // https://swiperjs.com/api/#methods
    // `swiper.slidePrev()` or `swiper.slideNext()`
    swiper[directionMethod]()
  }

  return (
    <div className={wrapperClassName}>
      <Container className={classNames('flex space-x-3', containerClassName)}>
        <SwiperNavCta
          label='Previous'
          className={classNames('transform rotate-180', ctaClassName)}
          onClick={(event) => handleOnClick(event, 'slidePrev')}
        />
        <SwiperNavCta
          label='Next'
          className={ctaClassName}
          onClick={(event) => handleOnClick(event, 'slideNext')}
        />
      </Container>
    </div>
  )
}

SwiperNav.propTypes = {
  swiper: PropTypes.object,
  wrapperClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  ctaClassName: PropTypes.string
}

export default SwiperNav
