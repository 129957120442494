import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper'
import Image from '../Image/Image'
import Link from '../Link/Link'

const LogoGroup = (props) => {
  const { className, fallbackAlt, logosDocument } = props

  if (logosDocument) {
    return (
      <div className={classNames('flex space-x-10', className)}>
        {logosDocument.data.images.map((item, itemIndex) => {
          return (
            <ConditionalWrapper
              key={itemIndex}
              condition={item.link.url}
              wrapper={(children) => <Link {...item.link}>{children}</Link>}
            >
              <Image
                fallbackAlt={fallbackAlt}
                className='h-12 md:h-20 w-auto'
                lazyImageClassName='max-h-full object-contain '
                {...item.image}
              />
            </ConditionalWrapper>
          )
        })}
      </div>
    )
  }

  return null
}

LogoGroup.propTypes = {
  className: PropTypes.string,
  fallbackAlt: PropTypes.string.isRequired,
  logosDocument: PropTypes.object
}

export default LogoGroup

export const query = graphql`
  fragment LogoGroup on PrismicLinkType {
    document {
      ... on PrismicLogoGroup {
        id
        data {
          images {
            image {
              ...Image
            }
            link {
              ...Link
            }
          }
        }
      }
    }
  }
`
