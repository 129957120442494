import PropTypes from 'prop-types'

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children

ConditionalWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  condition: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  wrapper: PropTypes.func.isRequired
}

export default ConditionalWrapper
