import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import HtmlContent from '../HtmlContent/HtmlContent'

// Icons
import ChevronDown from '../../../assets/img/chevron-down.svg'

const CurriculumTab = (props) => {
  const { tab, tabIndex } = props

  const [isOpen, setIsOpen] = useState(tabIndex === 0)

  /**
   * Simple handler to toggle the state of the current tab
   * @param  {Object} event
   * @return {Void}
   */
  const handleClick = (event) => {
    event.preventDefault()

    setIsOpen(!isOpen)
  }

  return (
    <li>
      <div
        className='flex justify-between items-center bg-secondary-light py-2 pl-4 pr-5 mb-4 cursor-pointer'
        onClick={handleClick}
      >
        <div>
          <p className='h6'>{tab.header}</p>
          <p className='text-sm'>{tab.value}</p>
        </div>
        <ChevronDown
          className={classNames('fill-current text-midnight w-3 h-2', { 'transform -rotate-90': !isOpen })}
        />
      </div>
      {isOpen && (
        <div className='mb-6'>
          <HtmlContent
            html={tab.body}
            className='text-lg c-prose'
          />
          {tab.footnote && (
            <HtmlContent
              html={tab.footnote}
              className='text-xs font-medium pt-4 c-prose'
            />
          )}
        </div>
      )}
    </li>
  )
}

CurriculumTab.propTypes = {
  tab: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
}

export default CurriculumTab
