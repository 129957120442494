import React from 'react'
import PropTypes from 'prop-types'

// Generic
import HtmlContent from '../HtmlContent/HtmlContent'
import Image from '../Image/Image'
import Heading from '../Heading/Heading'

const TwoColGridItem = (props) => {
  const { icon, header, body } = props

  return (
    <div>
      {icon && (
        <div className='w-12 mb-4'>
          <Image
            fallbackAlt={header.text}
            {...icon}
          />
        </div>
      )}
      {header && (
        <Heading
          className='h6 font-medium text-lg mb-2'
          {...header}
        />
      )}
      {body && (
        <HtmlContent
          html={body}
          className='text-lg c-prose'
        />
      )}
    </div>
  )
}

TwoColGridItem.propTypes = {
  icon: PropTypes.object,
  header: PropTypes.object,
  body: PropTypes.string
}

export default TwoColGridItem
