import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'
import LogoGroup from '../../shared/LogoGroup/LogoGroup'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

// Utils
import getBreakpointWithoutUnit from '../../../utils/getBreakpointWithoutUnit'

const Hero = (props) => {
  return (
    <>
      <section
        className={classNames('c-hero', {
          'c-hero--has-blocks': props.data.hasBlocks
        })}
      >
        <div className='c-hero__inner'>
          <Container className='flex w-full'>
            <div className='c-hero__content'>
              <Heading
                className='h1'
                {...props.data.primary.hero_title}
              />
              <div className='c-hero__body space-y-4'>
                {props.data?.primary?.hero_intro?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_intro.html}
                    className='text-xl sm:text-2xl mt-6 c-prose'
                  />
                )}
                {props.data?.primary?.hero_body?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_body.html}
                    className='hidden sm:block text-lg mt-6 c-prose'
                  />
                )}
              </div>
              {(props.data.items && props.data.items.length > 0) && (
                <div className='mt-8 sm:mt-12 flex flex-wrap'>
                  {props.data.items.map((item, itemIndex) => {
                    return (
                      <Button
                        {...item.hero_cta_link}
                        className='mb-4 mr-4'
                        key={itemIndex}
                        color={item.cta_style}
                        icon
                      >
                        {item.hero_cta_label.text}
                      </Button>
                    )
                  })}
                </div>
              )}
              {props.additionalContent}
              <LogoGroup
                className='mt-auto pt-8 c-hero__logos'
                logosDocument={props.data.primary?.logo_group?.document}
                fallbackAlt={props.data.primary.hero_title.text}
              />
            </div>
          </Container>
          {props.data.primary?.hero_image?.url && (
            <div
              className={classNames('c-hero__img', {
                'text-primary': props.data.primary.hero_image_background === 'Primary',
                'text-secondary-dark': props.data.primary.hero_image_background === 'Secondary'
              })}
            >
              <Image
                className='relative z-10'
                fallbackAlt={props.data.primary.hero_title.text}
                lazyload={false}
                sizes={[
                  `(max-width: ${getBreakpointWithoutUnit('sm') - 1}px) 85vw`,
                  `(max-width: ${getBreakpointWithoutUnit('xl') - 1}px) 50vw`,
                  '686px'
                ]}
                {...props.data.primary.hero_image}
              />
            </div>
          )}
          {props.data?.primary?.hero_show_logo && <LogoSymbolRed className='hidden sm:block c-hero__logo' />}
        </div>
        {props.data?.primary?.hero_illustration?.url && (
          <div
            className='hidden sm:block c-hero__illustration'
          >
            <Container className='sm:px-0 xl:px-4'>
              <div className='c-hero__illustration-inner'>
                <Image
                  fallbackAlt={props.data.primary.hero_title.text}
                  lazyload={false}
                  sizes={[
                    '(max-width: 1399px) 45vw',
                    '630px'
                  ]}
                  {...props.data.primary.hero_illustration}
                />
              </div>
            </Container>
          </div>
        )}
      </section>
    </>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
  additionalContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ])
}

export default Hero

export const query = graphql`
  fragment Hero on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHero {
          id
          slice_type
          primary {
            hero_title {
              html
              text
              raw
            }
            hero_intro {
              html
            }
            hero_body {
              html
            }
            hero_image {
              ...Image
            }
            hero_image_background
            hero_illustration {
              ...Image
            }
            hero_show_logo
            logo_group {
              ...LogoGroup
            }
          }
          items {
            hero_cta_label {
              text
            }
            hero_cta_link {
              ...Link
            }
            cta_style
          }
        }
      }
    }
  }
`
