import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Icons
import ChevronRight from '../../../assets/img/chevron-right.svg'

const SwiperNavCta = (props) => {
  const { onClick, className, label } = props

  return (
    <button
      aria-label={label}
      className={classNames('flex items-center justify-center w-12 h-12 rounded-full transition-colors duration-300 focus:outline-none', className)}
      onClick={onClick}
    >
      <ChevronRight className='h-4 fill-current' />
    </button>
  )
}

SwiperNavCta.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired
}

export default SwiperNavCta
