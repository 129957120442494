import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Pagination } from 'swiper'

// Generic
import Button from '../../shared/Button/Button'
import Edge from '../../shared/Edge/Edge'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Container from '../../shared/Container/Container'

// Swiper components
import SwiperNav from '../../shared/Swiper/SwiperNav'

SwiperCore.use([EffectFade, Pagination])

const TestimonialCarousel = (props) => {
  const { items, sectionClassName, preSwiperInnerClassName, itemClassName, hideBottomEdge } = props

  const [swiper, setSwiper] = useState(null)

  return (
    <section className={classNames(sectionClassName)}>
      <Edge
        location='top'
        direction='left'
        outerClassName='text-white'
        innerClassName='text-primary'
      />
      <div
        className={classNames('relative pb-10 md:pb-0 bg-primary c-testimonials', {
          'md:pb-20': hideBottomEdge
        })}
      >
        <Container>
          <div className={classNames(preSwiperInnerClassName)}>
            <Swiper
              className='pb-12 md:pb-16 overflow-visible'
              effect='fade'
              lazy
              loop
              onSwiper={setSwiper}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet',
                bulletActiveClass: 'swiper-pagination-bullet-active text-white'
              }}
              preloadImages={false}
              slidesPerView={1}
              spaceBetween={0}
            >
              {items.map((testimonial, testimonialIndex) => {
                return (
                  <SwiperSlide
                    key={testimonialIndex}
                    className='relative flex flex-col justify-center h-auto'
                  >
                    <div className={classNames('text-center max-w-4xl mx-auto', itemClassName)}>
                      <div
                        className='w-24 h-24 mx-auto border-2 border-white rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 overflow-hidden'
                        style={{
                          '--transform-translate-y': 'calc(-50% + -3.5vw)'
                        }}
                      >
                        <Image
                          fallbackAlt={testimonial.testimonial_carousel_name.text}
                          {...testimonial.testimonial_carousel_avatar}
                        />
                      </div>
                      <HtmlContent
                        html={testimonial.testimonial_carousel_body.html}
                        className='h5 sm:h4 md:h3 font-bold text-white mb-10 pt-16 md:pt-10 c-prose'
                        element='blockquote'
                      />
                      <p className='text-white text-xl mb-3 font-bold'>{testimonial.testimonial_carousel_name.text}</p>
                      <div
                        className={classNames('flex items-center justify-center', {
                          'mb-10': (testimonial.testimonial_carousel_cta_label.text && testimonial.testimonial_carousel_cta_link.url)
                        })}
                      >
                        <div className='text-white bg-black p-2 text-sm md:text-lg font-medium'>
                          <HtmlContent html={testimonial.testimonial_carousel_role.html} />
                        </div>
                      </div>
                      {(testimonial.testimonial_carousel_cta_label.text && testimonial.testimonial_carousel_cta_link.url) && (
                        <Button
                          className='text-white hover:text-white'
                          color='inline'
                          icon
                          {...testimonial.testimonial_carousel_cta_link}
                        >
                          {testimonial.testimonial_carousel_cta_label.text}
                        </Button>
                      )}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </Container>
        <SwiperNav
          swiper={swiper}
          wrapperClassName={classNames('hidden md:block w-full absolute bottom-0 left-0 z-10', {
            '-mb-10': !hideBottomEdge,
            'transform -translate-y-8': hideBottomEdge
          })}
          containerClassName='justify-end'
          ctaClassName='text-white border-2 border-midnight border-opacity-25 hover:border-white hover:border-opacity-100'
        />
      </div>
      {!hideBottomEdge && (
        <Edge
          location='bottom'
          direction='left'
          outerClassName='text-white'
          innerClassName='text-primary'
        />
      )}
    </section>
  )
}

TestimonialCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  sectionClassName: PropTypes.string,
  preSwiperInnerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  hideBottomEdge: PropTypes.bool
}

export default TestimonialCarousel

export const query = graphql`
  fragment TestimonialCarousel on PrismicTestimonialCarousel {
    id
    data {
      testimonial_carousel_items {
        testimonial_carousel_role {
          html
        }
        testimonial_carousel_name {
          text
        }
        testimonial_carousel_cta_label {
          text
        }
        testimonial_carousel_cta_link {
          ...Link
        }
        testimonial_carousel_body {
          html
        }
        testimonial_carousel_avatar {
          ...Image
        }
      }
    }
  }
`
