import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import HtmlContent from '../HtmlContent/HtmlContent'

// Local
import CurriculumTab from './CurriculumTab'

const CurriculumTabs = (props) => {
  const { tabs } = props

  const tabsWrapperElement = useRef(null)

  const [currentTab, setCurrentTab] = useState(0)
  const [minHeight, setMinHeight] = useState(0)

  useEffect(() => {
    /**
     * Loop through the direct children of the wrapper element and get the
     * height of each one. Set the minHeight to the tallest element
     * @return {Void}
     */
    const calculateMinHeight = () => {
      if (tabsWrapperElement.current) {
        // Use a temp variable so the state isn't being updated each iteration
        let tempMinHeight = minHeight
        tabsWrapperElement.current.childNodes.forEach((childElement) => {
          // Get the height of the element and compare against the current
          // tallest element
          if (childElement.offsetHeight > tempMinHeight) {
            tempMinHeight = childElement.offsetHeight
          }
        })

        // Save to the state
        setMinHeight(tempMinHeight)
      }
    }

    // Run when the component is ready, and on resize
    calculateMinHeight()
    window.addEventListener('resize', calculateMinHeight)

    return () => {
      window.removeEventListener('resize', calculateMinHeight)
    }
  }, [])

  /**
   * Changes to the visible content to the currently selected index
   * @param  {Object} event
   * @param  {Number} tabIndex
   * @return {Void}
   */
  const handleTabClick = (event, tabIndex) => {
    event.preventDefault()

    setCurrentTab(tabIndex)
  }

  return (
    <>
      <ul className='sm:hidden space-y-2'>
        {tabs.map((tab, tabIndex) => {
          return (
            <CurriculumTab
              key={tabIndex}
              tabIndex={tabIndex}
              tab={tab}
            />
          )
        })}
      </ul>
      <div className='hidden sm:block'>
        <ul className='flex space-x-4 mb-10'>
          {tabs.map((tab, tabIndex) => {
            return (
              <li key={tabIndex}>
                <button
                  className={classNames('p-2 uppercase leading-none text-xs border border-primary rounded tracking-wider font-medium hover:bg-primary hover:bg-opacity-50 hover:text-white focus:outline-none', {
                    'bg-primary text-white hover:bg-opacity-100': tabIndex === currentTab,
                    'text-primary': tabIndex !== currentTab
                  })}
                  onClick={(event) => handleTabClick(event, tabIndex)}
                >
                  {tab.value}
                </button>
              </li>
            )
          })}
        </ul>
        <div
          className='relative'
          ref={tabsWrapperElement}
          style={{ minHeight: `${minHeight}px` }}
        >
          {tabs.map((tab, tabIndex) => {
            return (
              <div
                key={tabIndex}
                className={classNames({
                  'relative z-10': tabIndex === currentTab,
                  'absolute opacity-0 pointer-events-none': tabIndex !== currentTab
                })}
              >
                <p className='h4 mb-4'>{tab.header}</p>
                <HtmlContent
                  html={tab.body}
                  className='text-lg c-prose'
                />
                {tab.footnote && (
                  <HtmlContent
                    html={tab.footnote}
                    className='text-xs font-medium pt-8 c-prose'
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

CurriculumTabs.propTypes = {
  tabs: PropTypes.array.isRequired
}

export default CurriculumTabs
