import { screens as tailwindScreens } from 'tailwindcss/defaultTheme'

/**
 * Returns the breakpoint value without the `px` unit on the end
 * @param  {String} breakpointLabel
 * @return {Number}
 */
const getBreakpointWithoutUnit = (breakpointLabel) => {
  if (!tailwindScreens[breakpointLabel]) {
    throw new Error(`Breakpoint ${breakpointLabel} not found in Tailwind config`)
  }

  return parseInt(tailwindScreens[breakpointLabel].replace('px', ''))
}

export default getBreakpointWithoutUnit
