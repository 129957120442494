import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Local
import FaqsItem from './FaqsItem'

const Faqs = (props) => {
  const { items, className, isOpen, canAnimate } = props

  return (
    <ul className={classNames('divide-y divide-gray-lighter', className)}>
      {items.map((item, itemIndex) => {
        return (
          <FaqsItem
            key={itemIndex}
            header={item.header}
            body={item.body}
            isOpen={isOpen}
            canAnimate={canAnimate}
          />
        )
      })}
    </ul>
  )
}

Faqs.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  canAnimate: PropTypes.bool
}

export default Faqs
